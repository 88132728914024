/* was just reading a bit about using requestAnimationFrame for animations inside components
and found a tut on css tricks
and had to look into useeffects hook to understand what is going on
so the useeffect(takes a cb function to run)
used like this the function is run on each render
if from within the cb you return a fn <- this fn will be run on cleanup phase
the cleaup phase also runs on every rerender + when component unmounts
then if you pass an arr of var to useEffect(cb,arr), the cb will run only when arr changes
if you pass empty arr, the effect runs only once. 
in that case the props and state will always have their initial vals 
which might be a problem in case effect triggers a loop that eg requires and updates state 
as is my case here, for animation <- this is what the css-tricks article explains */

import React,{useState,useRef,useEffect,createRef} from 'react';
import styled from 'styled-components';

const speedDefault = 5/1280*window.screen.width;
const fps = 60;

const directions = {
    '👉👆' : ({x,y,speed})=>({x:x+speed,y:y-speed}),
    '👉👇' : ({x,y,speed})=>({x:x+speed,y:y+speed}),
    '👈👇' : ({x,y,speed})=>({x:x-speed,y:y+speed}),
    '👈👆' : ({x,y,speed})=>({x:x-speed,y:y-speed}),
} 
const bounceX = {
    '👈👆':'👉👆',
    '👉👆':'👈👆',
    '👈👇':'👉👇',
    '👉👇':'👈👇',
}
const bounceY = {
    '👈👆':'👈👇',
    '👉👆':'👉👇',
    '👈👇':'👈👆',
    '👉👇':'👉👆',
}

// i wont be using initPosition now, will spawn in the middle
function initPosition(elem,others){
    if(!elem) return;
    debugger
    let isOk = false;
    let x,y;
    while(!isOk){
        x = Math.floor(Math.random()*(window.innerWidth-elem.offsetWidth));
        y = Math.floor(Math.random()*(window.innerHeight-elem.offsetHeight));
        const right = elem.offsetWidth + x ;
        const bottom = elem.offsetHeight + y ;
        if(!others.length) isOk = true
        others.forEach(i=>{
            const {
                right: rightOther,
                bottom: bottomOther,
                width: widthOther,
                height: heightOther} = i.getBoundingClientRect();
            if(
                Math.abs(bottom-bottomOther) > Math.max(elem.offsetHeight,heightOther) &&
                Math.abs(right-rightOther) > Math.max(elem.offsetWidth,widthOther)
            ) isOk = true ;
        });
        return {x,y};
    } 
}

function checkWindowBounce(elem,direction,{callbackX,callbackY}){
    const {top,right,bottom,left} = elem.getBoundingClientRect();    
    if(left<=1 && ['👈👆','👈👇'].indexOf(direction)+1 ){
        callbackX();
    }
    if(window.innerWidth-right<=1 && ['👉👆','👉👇'].indexOf(direction)+1 ){
        callbackX();
    }
    if(top<=1 && ['👈👆','👉👆'].indexOf(direction)+1){
        callbackY();
    }
    if(window.innerHeight-bottom<=1 && ['👉👇','👈👇'].indexOf(direction)+1){
        callbackY();
    }
}

function checkElementsBounce(elem1,elem2,direction,speed,{callbackX,callbackY}){
    const rect1 = elem1.getBoundingClientRect();
    const rect2 = elem2.getBoundingClientRect();
    const isOverlap = checkOverlap(rect1,rect2,direction,speed)
    switch (isOverlap) {
        case 'x':
            callbackX(elem1,elem2);
            break;
        case 'y':
            callbackY(elem1,elem2);
            break;
        default:
            break;
    }    
}

function checkOverlap(rect1,rect2,direction,speed){
    if (
        Math.abs(rect1.left-rect2.right) < speed * 1.8 &&
        // Math.abs(rect1.bottom-rect2.bottom) < Math.max(rect1.height,rect2.height) &&
        Math.sign(rect1.top-rect2.bottom) * Math.sign(rect1.bottom-rect2.top) < 0 &&
        direction.includes('👈')
    ) return 'x';
    if (
        Math.abs(rect1.right-rect2.left)< speed * 1.8 &&
        // Math.abs(rect1.bottom-rect2.bottom) < Math.max(rect1.height,rect2.height) &&
        Math.sign(rect1.top-rect2.bottom) * Math.sign(rect1.bottom-rect2.top) < 0 &&
        direction.includes('👉')
    ) return 'x';
    if(
        Math.abs(rect1.top-rect2.bottom)< speed * 1.8 &&
        // Math.abs(rect1.right-rect2.right) < Math.max(rect1.width,rect2.width) &&
        Math.sign(rect1.right-rect2.left) * Math.sign(rect1.left-rect2.right) < 0 &&
        direction.includes('👆')
    ) return 'y';
    if(
        Math.abs(rect1.bottom-rect2.top)< speed * 1.8 &&
        // Math.abs(rect1.right-rect2.right) < Math.max(rect1.width,rect2.width) &&
        Math.sign(rect1.right-rect2.left) * Math.sign(rect1.left-rect2.right) < 0 &&
        direction.includes('👇')
    ) return 'y';
}

/* ! i can also abstract the requestAnimationFrame logic 
into a custom hook (see: https://css-tricks.com/using-requestanimationframe-with-react-hooks/) 
but im not gonna do it now */

function BouncingComponent({className, children, speed = speedDefault}){
    const bouncingElement = useRef(null);    
    
    const [position,setPosition] = useState({
        x:window.innerWidth/2,
        y:window.innerHeight/2,
    });
    
    /*  
    // this doesnt work the way it should, but for im spawning in the middle anyways
    useEffect(()=>{
        setPosition(initPosition(        
            bouncingElement.current,
            otherBouncingElements))
     },[bouncingElement.current])
     */
    
    
    const [direction,setDirection] = useState((()=>{
        const directionsKeys = Object.keys(directions);
        return directionsKeys[Math.floor(Math.random()*directionsKeys.length)];
    })())    
    
    const requestRef = useRef(); // this is a ref to reqAnimFrame;
    const prevTimeRef = useRef(0); // this is for controllingFPS;
    

    function animate(time){
        requestRef.current = requestAnimationFrame(animate);
        if(time - prevTimeRef.current > 1000/fps){
            const otherBouncingElements = [...document.querySelectorAll('.rigid-body')]
                .filter(i=>i!=bouncingElement.current);
            prevTimeRef.current = time
            setPosition(prevValue => directions[direction]({...prevValue,speed}));            
            checkWindowBounce(bouncingElement.current,direction,{
                callbackX:()=>{setDirection(prevDir=>(bounceX[prevDir]))},
                callbackY:()=>{setDirection(prevDir=>(bounceY[prevDir]))}
            }
            )
            otherBouncingElements.forEach(i=>{
                checkElementsBounce(bouncingElement.current,i,direction,speed,{
                    callbackX:(el1,el2)=>{
                        setDirection(prevDir=>(bounceX[prevDir]));
                        console.debug('x bounce between', el1,el1.getBoundingClientRect(), el2,el2.getBoundingClientRect())
                    },
                    callbackY:(el1,el2)=>{
                        setDirection(prevDir=>(bounceY[prevDir]));
                        console.debug('y bounce between', el1,el1.getBoundingClientRect(), el2,el2.getBoundingClientRect())
                    }
                })
            })
            
        }
        // todo? : the movement will vary dep on framerate
        //      should i check deltaTime to setPosition ? < yes i do
        // also, i dont think its very efficient, my macbook is flyin off
        // todo : check if bounces off of something
        /* if you pass a cb to setPosition prevState is passed as parameter
              we have to do it with cb instead of just passing a value
              cause we run the animate as effect, once, and so
                the the props and states in its scope are the initial ones  */
          
    }    

    useEffect(()=>{
        requestRef.current = requestAnimationFrame(animate);
        return ()=>cancelAnimationFrame(requestRef.current);
    },[direction]) // empty array runs effect only once
    
    /* theres an intersect listener from npm 
    but if i register it inside the component, 
    will it register it again on each rerender ?
    ill check with consolelog 
    + if i query for bouncing elems, will it gather all?
        or just the ones that were rendered before this component?  */        
    
    /* if(bouncingElement.current){                
        listenForElementsBounce(bouncingElement.current,
            otherBouncingElements,
            ({elem1,elem2})=>{
                const elem1Bounds = elem1.getBoundingClientRect();
                const elem2Bounds = elem2.getBoundingClientRect();                
                
                if(CheckOverlapX(elem1Bounds.left,elem1Bounds.right,elem2Bounds.left,elem2Bounds.right)){
                    setDirection(prevDir=>(
                        {
                            '👈👆':'👉👆',
                            '👉👆':'👈👆',
                            '👈👇':'👉👇',
                            '👉👇':'👈👇',
                        }[prevDir]
                    ))
                }
                if(CheckOverlapY(elem1Bounds.top,elem1Bounds.bottom,elem2Bounds.top,elem2Bounds.bottom)){                    
                    setDirection(prevDir=>(
                        {
                            '👈👆':'👈👇',
                            '👉👆':'👉👇',
                            '👈👇':'👈👆',
                            '👉👇':'👉👆',
                        }[prevDir]
                    ))
                };
                console.log(
                    `elem ${[...elem1.parentElement.childNodes].indexOf(elem1)} intersects ${[...elem2.parentElement.childNodes].indexOf(elem2)} elem
                    elemA: top:${elem1Bounds.top} bottom:${elem1Bounds.bottom} left:${elem1Bounds.left} right:${elem1Bounds.right}
                    elemB: top:${elem2Bounds.top} bottom:${elem2Bounds.bottom} left:${elem2Bounds.left} right:${elem2Bounds.right}`
                    );                
                // now i need to check where do they overlap, (but if i do i guess i dont need the listen forOverlap module at all) so i can 
            });
    } */

    return(
        <div 
            className={`${className} bouncing-element rigid-body`} 
            style={{
                left:position.x,
                top:position.y,}}
            ref={bouncingElement}
        >
            {children}
        </div>
    )
}

const StyledBouncingComponent = styled(BouncingComponent)`
    position:absolute;
    transform: translate(-50%,-50%);

`
export default StyledBouncingComponent;