import StyledBouncingComponent from './components/BouncingComponent.js';
import styled,{createGlobalStyle} from 'styled-components';
import IBMPlexSansExtraLight from './fonts/IBMPlexSans-ExtraLight.ttf';
import {updateWindowDimensionsVar} from './css_vars/cssVars.js';
import _ from 'lodash';

window.addEventListener('resize',_.throttle(()=>{
  updateWindowDimensionsVar('--windowW','--windowH');
},1000/15))
updateWindowDimensionsVar('--windowW','--windowH');

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'IBM Sans Serif ExtraLight';
    src: local('IBM Sans Serif ExtraLight'), local('IBMSansSerifExtraLight'),
      url(${IBMPlexSansExtraLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }
  html, body, .App, .App>div {
    /* height: ${({windowHeight})=>windowHeight/2+'px'}; */
    height: calc(var(--windowH)*1px);
    min-height: 520px;
  }
  @media(max-width:779px){
    html{
      overflow:hidden;
    }
    html, body, .App, .App>div {
    min-height: unset;
  }
  }
`
const StyledBouncingContainer = styled.div`

  position: relative;

  ${(props) => props.debug && `
    .rigid-body{
      background-color: blue;  
    }
  `}
  
  .static-body, ${StyledBouncingComponent} {
    transform: scale(calc(var(--windowW)/1280*0.9));
  }

  .static-body.left-top{
    top: 130px;
    left: 130px;
    transform-origin: 0% 0%;
  }

  .static-body.right-bottom{
    bottom:130px;
    right:130px;
    transform-origin: 100% 100%;
  }

  @media(max-width:779px){
      .static-body, ${StyledBouncingComponent} {
      transform: scale(calc(var(--windowW)/1280*0.9));
    }

    .static-body.left-top{
      top: 70px;
      left: 70px;
    }

    .static-body.right-bottom{
      bottom:70px;
      right:70px;
    } 
  }

  .static-body{
    /* display: inline-block; */
    position:absolute;
    
  }
  .static-body.sections-container{
    display:flex;
  }
  span{
    font-size: 188px;
    font-family: 'IBM Sans Serif ExtraLight';  
  }
  span.stefan{
    height: 143px;
    display: block;
    line-height: 75%;
    width: 480px;
    transform: translate(-8px, 0px);
  }
  span.glowacki{
    height: 143px;
    display: block;
    line-height: 75%;
    width: 690px;
    transform: translate(-8px, 0px);
  }
  span.cv{
    height: 104px;
    display: block;
    line-height: 33%;
    width: 168px;
    transform: translate(-8px, 0px);
  }
  .static-body > span > div {
    z-index: -1;
    position: absolute
  }
  span.glowacki .section-g{
    height: 100%;
    width: 86px;  
    top: 25%;
    left: 1.5%;  
  }

  span.glowacki .section-l{
    height: 100%;
    width: 11px;  
    top: 0%;
    left: 16.8%;  
  }
  span.glowacki .section-owac{
    height: 70%;
    width: 68%;  
    top: 30%;
    left: 18.5%;  
  }
  span.glowacki .section-ki{
    height: 100%;
    width: 15%;  
    top: 0;
    left: 86%;  
  }
  span.stefan .section-s{
    height: 70%;
      width: 20.5%;
      top: 30%;
      left: 2%;
  }
  span.stefan .section-t{
    height: 90%;
      width: 2.4%;
      top: 10%;
      left: 22.5%;
      
  }
  span.stefan .section-e{
    height: 70%;
      width: 31%;
      top: 30%;
      left: 25%;
  }
  span.stefan .section-f{
    height: 100%;
      width: 7%;
      top: 0px;
      left: 56%;
  }
  span.stefan .section-an{
    height: 75%;
      width: 39%;
      top: 25%;
      left: 63%;
  }
  /* #firstName:hover{
    color:blue;
  }
  #firstName:hover ~ #lastName{
    color:blue;
  }
  #lastName:hover ~ #firstName{
    color:blue;
  }
  #lastName:hover{
    color:blue;
  } */
  
  a{
    color:black;
    text-decoration: none;
  }
  a:hover{
    color:blue;
  }
  #firstName:hover, #lastName:hover{
    cursor:pointer
  }

`

function App() {  
  
  function handleNameHover(){
    document.querySelector('#firstName').style.color = 'blue';
    document.querySelector('#lastName').style.color = 'blue';
  }
  function handleNameHoverEnd(){
    document.querySelector('#firstName').style.color = 'black';
    document.querySelector('#lastName').style.color = 'black';
  }
  function handleNameClick(){
    window.location.href = "mailto:stefanglowacki.contact@gmail.com";
  }
  return (
    <>
    <GlobalStyle/>
    <div className="App">
    <StyledBouncingContainer debug={false}>
      <StyledBouncingComponent>
        <span className='cv'>
          <a href='portfolio_2023_eng_website.pdf' 
            target='_blank'>cv</a>
          </span>
      </StyledBouncingComponent>
      <div id='firstName' className='static-body left-top'
        onMouseOver={handleNameHover}
        onMouseLeave={handleNameHoverEnd}
        onClick={handleNameClick}>
        <span className='stefan'>
          stefan
          <div className='rigid-body section-s'></div>
          <div className='rigid-body section-t'></div>
          <div className='rigid-body section-e'></div>
          <div className='rigid-body section-f'></div>
          <div className='rigid-body section-an'></div>
        </span>
      </div>
      <div id='lastName' className='static-body right-bottom'
        onMouseOver={handleNameHover}
        onMouseLeave={handleNameHoverEnd}
        onClick={handleNameClick}>
        <span className='glowacki'>
        glowacki
          <div className='rigid-body section-g'></div>
          <div className='rigid-body section-l'></div>
          <div className='rigid-body section-owac'></div>
          <div className='rigid-body section-ki'></div>
        </span>         
      </div>
    </StyledBouncingContainer>
    </div>
    </>
  );
}

export default App;
